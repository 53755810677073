<template>
    <div class="avatar-wrapper" :class="[extraClasses, resolvedSize, square ? 'square' : 'round']">
        <img v-if="avatarUrl" :src="avatarUrl" class="avatar" />
        <div v-if="initials" class="initials" :style="computedStyle">{{ initials }}</div>
        <i v-else class="fa fa-user" />
    </div>
</template>

<script lang="ts" setup>
import md5 from 'blueimp-md5';
import { computed } from 'vue';

import { computeColorFromString, getTextColor } from '../helpers/color.helpers';

const props = defineProps<{
    contact: {
        id: string;
        avatarId?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        displayName?: string | null;
        company?: string | null;
        email?: string | null;
    };
    extraClasses?: string;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'max';
    square?: boolean;
}>();

const resolvedSize = computed(() => props.size ?? 'sm');

const avatarUrl = computed(() => {
    if (props.contact.avatarId) {
        const suffix = props.size !== 'max' ? '_t256' : '';
        const components = [
            import.meta.env.VITE_APP_AVATAR_BASE_URL,
            props.contact.avatarId.substring(0, 2),
            props.contact.avatarId.substring(2, 4),
            props.contact.avatarId.substring(4, 6),
            props.contact.avatarId.substring(6, 8),
            `${props.contact.avatarId}${suffix}`
        ];
        return components.join('/');
    }

    if (props.contact.email) {
        const hash = md5(props.contact.email);
        return `https://www.gravatar.com/avatar/${hash}?s=256&d=blank`;
    }

    return null;
});

const initials = computed(() => {
    if (props.contact.displayName) {
        const names = props.contact.displayName.split(' ');
        return names.length > 1 ? `${names[0].substring(0, 1)}${names[names.length - 1].substring(0, 1)}` : names[0].substring(0, 1);
    }
    if (props.contact.firstName && props.contact.lastName) {
        return `${props.contact.firstName.substring(0, 1)}${props.contact.lastName.substring(0, 1)}`;
    }
    if (props.contact.firstName) {
        return props.contact.firstName.substring(0, 1);
    }
    if (props.contact.lastName) {
        return props.contact.lastName.substring(0, 1);
    }
    if (props.contact.company) {
        return props.contact.company.substring(0, 1);
    }
    return null;
});

const backgroundColor = computed(() => {
    const rgb = computeColorFromString(initials.value ?? props.contact.id);
    return rgb;
});

const computedStyle = computed(() => {
    return {
        backgroundColor: `rgb(${backgroundColor.value.r}, ${backgroundColor.value.g}, ${backgroundColor.value.b})`,
        color: getTextColor(backgroundColor.value)
    };
});
</script>

<style lang="scss" scoped>
.avatar-wrapper {
    @apply relative flex items-center justify-center flex-shrink-0 overflow-hidden;

    .initials {
        @apply text-[1em] pointer-events-none;
    }

    &.square {
        @apply rounded-md;
    }

    &.round {
        @apply rounded-full;
    }

    &.xs {
        @apply h-6 w-6;
    }

    &.sm {
        @apply h-10 w-10 text-[1em];
    }

    &.md {
        @apply h-14 w-14 text-[1.5em];
    }

    &.lg {
        @apply h-32 w-32 text-[3em];
    }

    &.xl {
        @apply h-52 w-52 text-[4em];
    }

    &.max {
        @apply w-full aspect-square text-[5em];
    }

    &:after {
        @apply absolute text-xl font-medium text-gray-400 tracking-wide;
        content: attr(data-initials);
    }

    img {
        @apply absolute inset-0 w-full h-full object-cover;
    }
}

.avatar {
    @apply absolute bg-transparent;
}

.initials {
    @apply flex-1 h-full m-[0.05rem] flex items-center justify-center font-bold;
}
</style>
