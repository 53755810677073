<template>
    <div class="layout-wrap">
        <Qab v-if="shouldDisplayQab" />

        <main>
            <slot />
            <div class="version wide">{{ displayVersion }}</div>
        </main>
    </div>
</template>

<script lang="ts" setup>
import { compact } from 'lodash';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { GlobalState } from '@/global';
import Qab from '@/modules/core/qab.vue';

import { SessionManagement } from '../services/session-management.service';

const router = useRouter();

const zdVersion = navigator.userAgent.match(/^ZynoDesktop\/([\d.]+)/)?.[1]?.replace(/\.0$/, '');
const displayVersion = compact([zdVersion, import.meta.env.VITE_APP_VERSION]).join(' | ');

const shouldDisplayQab = computed(() => {
    return !!GlobalState.user.value && router.currentRoute.value.meta?.public !== true;
});

SessionManagement.install(router);
</script>

<style type="text/scss" scoped>
.layout-wrap {
    @apply flex-1 flex flex-col p-4 gap-3;
}

main {
    @apply flex-1 flex flex-col overflow-hidden;
}

.version {
    @apply absolute bottom-0 left-0 w-full text-center text-xs text-dark-4 p-4 -z-10;
}

:deep(main) {
    /* main will contain a single div: the active route's component */
    /* that will _typically_ contain a "header" and "body" div */
    > div {
        @apply flex-1 flex flex-col overflow-hidden /* min-h-0 */;

        &:not(.wide) {
            @apply mx-auto w-full max-w-screen-xl;
        }

        &.wide.auto {
            @apply self-center min-w-screen-xl;
        }

        > .header {
            @apply py-2 /* can't use gap-2 on the parent or the shadow on the selects gets cut off */;
        }

        > .body {
            @apply flex-1 bg-white rounded-md relative;
        }
    }
}
</style>
