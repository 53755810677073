import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router';

import { GlobalState } from './global';
import Launcher from './modules/core/launcher.vue';
import Login from './modules/core/login.vue';
import { CRMRoutes } from './modules/crm/routes';
import Desktop from './modules/desktop/desktop.vue';
import { DesktopRoutes } from './modules/desktop/routes';
import { DisplayRoutes } from './modules/display/routes';
import { FormsRoutes } from './modules/forms/routes';
import { InventoryRoutes } from './modules/inventory/routes';
import { ItRoutes } from './modules/it/routes';
import { SalesRoutes } from './modules/sales/routes';
import { TalkRoutes } from './modules/talk/routes';
import { wrapRuntimeImport } from './shared/helpers/async.helpers';
import { Auth } from './shared/services/auth.service';

declare module 'vue-router' {
    interface RouteMeta {
        public?: boolean;
        admin?: boolean;
        app?: string; // use makePermissionMeta
        feature?: string | string[];
    }
}

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        component: Launcher
    },
    {
        path: '/zd',
        component: Desktop,
        children: DesktopRoutes
    },
    {
        path: '/crm',
        component: wrapRuntimeImport(() => import('./modules/crm/crm.vue')),
        children: CRMRoutes
    },
    {
        path: '/display',
        component: wrapRuntimeImport(() => import('./modules/display/display.vue')),
        children: DisplayRoutes
    },
    {
        path: '/forms',
        component: wrapRuntimeImport(() => import('./modules/forms/forms.vue')),
        children: FormsRoutes
    },
    {
        path: '/inventory',
        component: wrapRuntimeImport(() => import('./modules/inventory/inventory.vue')),
        children: InventoryRoutes
    },
    {
        path: '/it',
        component: wrapRuntimeImport(() => import('./modules/it/it.vue')),
        children: ItRoutes
    },
    {
        path: '/sales',
        component: wrapRuntimeImport(() => import('./modules/sales/sales.vue')),
        children: SalesRoutes
    },
    {
        path: '/talk',
        component: wrapRuntimeImport(() => import('./modules/talk/talk.vue')),
        children: TalkRoutes
    },

    {
        name: 'login',
        path: '/login',
        component: Login,
        meta: { public: true }
    },
    {
        name: 'login:callback',
        path: '/login/callback',
        component: Login,
        meta: { public: true }
    },
    {
        name: 'logout',
        path: '/logout',
        component: Login
    }
];

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes
});
GlobalState.router = router;

let hasAttemptedSessionLoad = false;
router.beforeEach(async to => {
    if (!hasAttemptedSessionLoad) {
        await Auth.loadExistingSession();
        hasAttemptedSessionLoad = true;
    }

    if (to.meta.public === true) {
        return;
    }

    if (!GlobalState.user.value) {
        return { name: 'login', query: { intendedUrl: to.fullPath } };
    }
});

export default router;
