import type { RouteRecordRaw } from 'vue-router';

import { wrapRuntimeImport } from '@/shared/helpers/async.helpers';
import { makePermissionMeta } from '@/shared/helpers/permissions.helpers';

import { SalesConfigRoutes } from './subapps/config/routes';

export const SalesRoutes: RouteRecordRaw[] = [
    {
        path: '',
        redirect: '/sales/salespoint'
    },
    {
        path: 'gift-cards',
        component: wrapRuntimeImport(() => import('./subapps/gift-cards/gift-cards.vue')),
        meta: makePermissionMeta('sales', 'giftCardsView')
    },
    {
        path: 'reports',
        component: wrapRuntimeImport(() => import('./subapps/reports/reports.vue')),
        meta: makePermissionMeta('sales', 'reportsView')
    },
    {
        path: 'config',
        children: SalesConfigRoutes
    },
    {
        path: 'salespoint',
        component: wrapRuntimeImport(() => import('./subapps/salespoint/salespoint.vue')),
        meta: makePermissionMeta('sales', 'sp')
    },
    {
        path: 'transactions',
        component: wrapRuntimeImport(() => import('./subapps/transactions/transactions.vue')),
        meta: makePermissionMeta('sales', 'transactionsView')
    }
];
