import { showAlert, showWait } from '@signal24/vue-foundation';

import { useStore } from '@/store';

import { UpdateChecker } from './update.helpers';

async function runBlockingAsync<T>(fn: () => Promise<T>): Promise<T> {
    const rootStore = useStore();
    rootStore.globalLoader = true;
    try {
        return await fn();
    } finally {
        rootStore.globalLoader = false;
    }
}

export function wrapBlockingAsync<T>(fn: () => Promise<T>): () => Promise<T> {
    return async () => {
        return runBlockingAsync(fn);
    };
}

export function wrapRuntimeImport<T>(fn: () => Promise<T>): () => Promise<T> {
    return async () => {
        try {
            return await runBlockingAsync(fn);
        } catch (err) {
            console.error('Failed to load:', err);
            const hasUpdates = await UpdateChecker.checkForUpdate();
            if (hasUpdates) {
                showWait('Updating ZynoSuite...');
            } else {
                await showAlert('An error occurred while loading a ZynoSuite component. The application will now reload and retry.');
                showWait('Reloading ZynoSuite...');
            }
            location.reload();
            throw err;
        }
    };
}
