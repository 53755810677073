interface IEmbeddedWindow {
    chrome: {
        webview: {
            postMessage: (message: string) => void;
            addEventListener: (e: 'message', listener: (event: { data: string }) => void) => void;
            hostObjects: {
                zdBridge: {
                    isDevelopment: () => Promise<boolean>;
                    generateHandshake: () => Promise<string>;
                    launchRegistration: () => Promise<void>;
                    performPostRegistrationTasks: () => Promise<void>;
                    hasFingerprintReader: () => Promise<boolean>;
                };
            };
        };
    };
}

export interface IInboundEventPayloads {
    fingerprint: { Png: string; Data: string };
}

declare global {
    interface Window {
        chrome: {
            webview: {
                postMessage: (message: string) => void;
                addEventListener: (e: 'message', listener: (event: { data: string }) => void) => void;
                hostObjects: {
                    zdBridge: {
                        isDevelopment: () => Promise<boolean>;
                        generateHandshake: () => Promise<string>;
                        launchRegistration: () => Promise<void>;
                        performPostRegistrationTasks: () => Promise<void>;
                        hasFingerprintReader: () => Promise<boolean>;
                    };
                };
            };
        };
    }
}

export class DesktopBridge {
    private static isDevelopment = false;
    private static embeddedWindow?: IEmbeddedWindow;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private static listeners: { [key: string]: ((data: any) => void)[] } = {};

    static isWithinZynoDesktop() {
        try {
            window.chrome.webview.hostObjects.zdBridge.isDevelopment();
            return true;
        } catch (err) {
            return false;
        }
    }

    static async init() {
        if (this.isWithinZynoDesktop()) {
            this.embeddedWindow = window as unknown as IEmbeddedWindow;
            this.embeddedWindow.chrome.webview.addEventListener('message', e => this.handleMessage(e.data));
            this.isDevelopment = await this.embeddedWindow.chrome.webview.hostObjects.zdBridge.isDevelopment();
        }
    }

    static get bridgeFunctions(): IEmbeddedWindow['chrome']['webview']['hostObjects']['zdBridge'] | undefined {
        if (this.isDevelopment) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return new Proxy({} as any, {
                get: (_target, prop) => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    return async (...args: any[]) => {
                        console.log('[DesktopBridge] invoking:', prop, args);
                        const bridge = this.embeddedWindow?.chrome.webview.hostObjects.zdBridge;
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const result = await (bridge as any)?.[prop](...args);
                        console.log(`[DesktopBridge] invocation result [${String(prop)}]:`, result);
                        return result;
                    };
                }
            });
        }
        return this.embeddedWindow?.chrome.webview.hostObjects.zdBridge;
    }

    private static handleMessage(message: string) {
        if (this.isDevelopment) console.log('[DesktopBridge] received message:', message);
        try {
            const { Event, Data } = JSON.parse(message);
            this.listeners[Event]?.forEach(listener => listener(Data));
        } catch (err) {
            console.error('[DesktopBridge] failed to parse message:', message);
        }
    }

    static addEventListener<T extends keyof IInboundEventPayloads>(e: T, listener: (data: IInboundEventPayloads[T]) => void) {
        if (!this.listeners[e]) {
            this.listeners[e] = [];
        }
        this.listeners[e].push(listener);
    }

    static removeEventListener<T extends keyof IInboundEventPayloads>(e: T, listener: (data: IInboundEventPayloads[T]) => void) {
        if (!this.listeners[e]) {
            return;
        }
        this.listeners[e] = this.listeners[e].filter(l => l !== listener);
    }

    static postMessage(message: string) {
        if (this.isDevelopment) console.log('[DesktopBridge] posting message:', message);
        this.embeddedWindow?.chrome.webview.postMessage(message);
    }
}

if (import.meta.env.VITE_APP_ENABLE_FAKE_DESKTOP_BRIDGE && !('chrome' in window && 'webview' in window.chrome)) {
    const eventListeners: ((event: { data: string }) => void)[] = [];

    window.chrome = {
        webview: {
            postMessage() {},

            addEventListener(_e: 'message', listener: (event: { data: string }) => void) {
                eventListeners.push(listener);
            },

            hostObjects: {
                zdBridge: {
                    async isDevelopment() {
                        return true;
                    },

                    async generateHandshake() {
                        return prompt('[DesktopBridge] Enter handshake string') as string;
                    },

                    async launchRegistration() {
                        console.log('[DesktopBridge] launchRegistration');
                    },

                    async performPostRegistrationTasks() {
                        console.log('[DesktopBridge] performPostRegistrationTasks');
                    },

                    async hasFingerprintReader() {
                        return false;
                    }
                }
            }
        }
    };
}
