<template>
    <div id="app">
        <div v-if="rootStore.globalError" id="global-error" v-text="rootStore.globalError" />

        <Transition v-else name="fade" mode="out-in">
            <Loader v-if="!routerIsReady" id="preloader" size="2xl"></Loader>
            <Layout v-else>
                <router-view v-slot="{ Component }" :key="routerViewKey">
                    <Transition :name="transition" mode="out-in">
                        <div v-if="!routeExists" id="error">The requested URL does not exist.</div>
                        <div v-else-if="!isAuthorized" id="error">You don't have access to the requested URL.</div>
                        <component :is="Component" v-else />
                    </Transition>
                </router-view>
            </Layout>
        </Transition>

        <OverlayContainer />

        <div id="popover-portal"></div>

        <Transition name="fade" mode="out-in">
            <div v-if="rootStore.globalLoader" id="root-loader">
                <div class="bg-wrap">
                    <Loader />
                </div>
            </div>
        </Transition>
    </div>
</template>

<script lang="ts" setup>
import { OverlayContainer } from '@signal24/vue-foundation';
import { computed, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

import Layout from '@/shared/components/layout.vue';
import Loader from '@/shared/components/loader.vue';

import { GlobalState } from './global';
import { hasRouteAccess } from './shared/helpers/permissions.helpers';
import { Freshdesk } from './shared/services/freshdesk';
import { useStore } from './store';

const rootStore = useStore();
const router = useRouter();

watch(GlobalState.user, () => document.body.classList[GlobalState.user.value ? 'add' : 'remove']('signed-in'));

const transition = ref<'scale' | ''>('scale');
router.beforeEach(async (to, from) => {
    if (from.path.substring(1).replace(/\/.*$/, '') === to.path.substring(1).replace(/\/.*$/, '')) {
        transition.value = '';
    } else {
        transition.value = 'scale';
    }
});

const routerIsReady = ref(false);
router.isReady().then(() => {
    routerIsReady.value = true;
});

const routeExists = computed(() => router.currentRoute.value.matched.length > 0);
const isAuthorized = computed(() => hasRouteAccess(router.currentRoute.value.path));

// we don't want the re-render to happen until after the permissions are reloaded so that we don't re-render
// something the user no longer has access to
const tenantIdForPermissions = ref('');
watch(GlobalState.permissions, () => {
    tenantIdForPermissions.value = GlobalState.tenant.value?.id ?? '';
});

const routerViewKey = computed(() => {
    // if it's an public route, we'll use "public" as the key so that changes to auth don't cause a re-render
    // this is especially important for the login screen
    if (router.currentRoute.value.meta.public) {
        return 'public';
    }

    return tenantIdForPermissions.value ?? '';
});

const titlePrefix = computed(() => {
    if (import.meta.env.MODE !== 'production') {
        const env = import.meta.env.MODE ?? 'development';
        const envKey = env.substring(0, 1).toUpperCase();
        return `[${envKey}] `;
    }
    return '';
});
const documentTitle = computed(() => {
    if (rootStore.titleOverride) {
        return `${titlePrefix.value}${rootStore.titleOverride}`;
    }
    const baseTitle = `Zyno${rootStore.rootAppName ?? 'Suite'}`;
    const titleSuffix = rootStore.subAppName ? ` - ${rootStore.subAppName}` : '';
    return `${titlePrefix.value}${baseTitle}${titleSuffix}`;
});
watch(
    documentTitle,
    () => {
        document.title = documentTitle.value;
    },
    { immediate: true }
);

onMounted(() => Freshdesk.prepare());
</script>

<style lang="scss">
@import './shared/styles/base.scss';
@import '@fontsource/inter/latin-200.css';
@import '@fontsource/inter/latin-300.css';
@import '@fontsource/inter/latin-400.css';
@import '@fontsource/inter/latin-500.css';
@import '@fontsource/inter/latin-600.css';
@import '@fontsource/inter/latin-700.css';
@import '@fontsource/inter/latin-800.css';
@import '@fontsource/inter/latin-900.css';
@import '@vuepic/vue-datepicker/dist/main.css';

#app {
    @apply flex-1 flex;
}

#global-error {
    @apply flex-1 flex justify-center items-center bg-gray-800 text-2xl text-red-300;
}

#popover-portal {
    @apply absolute w-full h-full top-0 left-0 pointer-events-none flex;
}

#preloader {
    @apply justify-self-center self-center;
}

#root-loader {
    @apply absolute top-0 left-0 w-full h-full flex items-end justify-center p-4;

    .bg-wrap {
        @apply bg-slate-100 rounded-full shadow-lg;
    }
}

#error {
    @apply items-center justify-center text-xl text-red-200;
}
</style>
