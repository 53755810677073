import type { RouteRecordRaw } from 'vue-router';

import { wrapRuntimeImport } from '@/shared/helpers/async.helpers';
import { makePermissionMeta } from '@/shared/helpers/permissions.helpers';

export const DisplayRoutes: RouteRecordRaw[] = [
    {
        path: '',
        redirect: '/display/devices'
    },
    {
        path: 'devices',
        component: wrapRuntimeImport(() => import('./screens/devices.vue')),
        meta: makePermissionMeta('display')
    }
];
