import type { App } from 'vue';

interface AnimationDirectiveOptions {
    cls: string;
    gap: number;
}

class AnimationDirective {
    private restartTimeout?: number;

    constructor(
        private el: HTMLElement,
        private options: AnimationDirectiveOptions
    ) {
        this.el.classList.add(this.options.cls);
        this.el.addEventListener('animationiteration', () => this.onAnimationEnd());
    }

    onAnimationEnd() {
        this.el.classList.remove(this.options.cls);
        this.restartTimeout = window.setTimeout(() => {
            this.restartTimeout = undefined;
            this.el.classList.add(this.options.cls);
        }, this.options.gap * 1000);
    }

    destroy() {
        if (this.restartTimeout) window.clearTimeout(this.restartTimeout);
    }
}

export function registerAnimationDirective(app: App) {
    app.directive('animation', {
        mounted(el, binding) {
            el._animation = new AnimationDirective(el, binding.value);
        },
        unmounted(el) {
            el._animation?.destroy();
        }
    });
}
