<template>
    <div v-if="user" ref="userWidget" class="widget">
        <button class="ghost p-1" @click="open" @contextmenu.prevent="openTenantSwitcher">
            <div class="active-tenant">
                <span class="name dark">{{ user.firstName }} {{ user.lastName }}</span>
                <span class="tenant dark">{{ tenant?.name }}</span>
            </div>
            <Avatar :contact="user" />
        </button>
    </div>
</template>

<script lang="ts" setup>
import { presentOverlay } from '@signal24/vue-foundation';
import { computed, ref } from 'vue';

import { GlobalState } from '@/global';
import TenantSwitcherModal from '@/modules/auth/exports/m-tenant-switcher.vue';
import Avatar from '@/shared/components/avatar.vue';

import UserMenu from './c-user/f-user-menu.vue';

const userWidget = ref<HTMLDivElement>();
const user = computed(() => GlobalState.user.value);
const tenant = computed(() => GlobalState.tenant.value);

function open() {
    presentOverlay(
        UserMenu,
        {},
        {
            anchor: {
                el: userWidget.value!,
                x: 'right'
            }
        }
    );
}

async function openTenantSwitcher() {
    presentOverlay(TenantSwitcherModal, {});
}
</script>

<style lang="scss" scoped>
button {
    .active-tenant {
        @apply flex flex-col gap-1 items-end mr-3;

        .name {
            @apply font-bold;
        }

        .tenant {
            @apply font-medium;
        }
    }
}
.popover-content {
    .menu {
        @apply py-1;

        li {
            @apply hover:bg-blue-600 hover:text-white p-3 cursor-pointer;
        }
    }
}
</style>
