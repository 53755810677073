<template>
    <VfModal id="m-auth-tenantSwitcher" ref="modal" scrolls close-on-mask-click>
        <template #header>
            <div class="input-wrapper">
                <i class="far fa-search" />
                <input v-model="searchQuery" v-autofocus type="text" placeholder="Search" class="search" />
            </div>
        </template>
        <ul class="tenant-list">
            <li v-for="tenant in filteredTenants" :key="tenant.id" class="tenant" :favorited="tenant.isFavorite" @click="setTenant(tenant)">
                <!-- <i class="far fa-star icon" :class="{ favorited: tenant.isFavorite }" /> -->
                <span class="name">{{ tenant.name }}</span>
            </li>
        </ul>
    </VfModal>
</template>

<script lang="ts" setup>
import { showWait, VfModal } from '@signal24/vue-foundation';
import { computed, ref } from 'vue';

import { GlobalState, type ITenant } from '@/global';
import { apiAuthClient } from '@/openapi-clients/auth';
import router from '@/router';
import { withWait } from '@/shared/helpers/request.helpers';
import { Auth } from '@/shared/services/auth.service';

const props = defineProps<{
    callback: () => void;
}>();

const modal = ref<InstanceType<typeof VfModal>>();
const searchQuery = ref('');

const activeTenantId = GlobalState.user.value?.tenantId;
const tenants = [...(GlobalState.user.value?.tenants ?? [])].sort((a, b) => {
    if (a.id === activeTenantId) {
        return -1;
    }
    if (b.id === activeTenantId) {
        return 1;
    }
    if (a.isFavorite && !b.isFavorite) {
        return -1;
    }
    if (!a.isFavorite && b.isFavorite) {
        return 1;
    }
    return a.name.localeCompare(b.name);
});
const filteredTenants = computed(() => {
    const filter = searchQuery.value.trim().toLowerCase();
    return tenants.filter(tenant => tenant.name.toLowerCase().includes(filter));
});

function setTenant(tenant: ITenant) {
    withWait(
        () => showWait('Switching tenants...'),
        async () => {
            const result = await apiAuthClient.login.postLoginSwitchTenant({
                requestBody: {
                    tenantId: tenant.id
                }
            });

            Auth.processRenewalResponse(result);
            Auth.setTenant(tenant);
            await Auth.reloadPermissions();

            const currentRoute = router.currentRoute.value;
            const matches = currentRoute.fullPath.match(/\/[0-9a-f-]{36}/);
            if (matches) {
                await router.push(currentRoute.fullPath.substring(0, matches.index!));
            }

            props.callback();
        }
    );
}
</script>

<style lang="scss">
#m-auth-tenantSwitcher {
    .vf-modal {
        @apply min-w-64 h-60 rounded-lg;
    }

    .vf-modal-header {
        @apply bg-white p-2 pb-0 text-black;
    }

    .vf-modal-content {
        @apply p-1;
    }
}
</style>

<style lang="scss" scoped>
.input-wrapper {
    flex: 1;

    input {
        flex: 1;
    }
}

.tenant-list {
    @apply m-1;

    .tenant {
        @apply flex items-center space-x-3 p-2 hover:bg-blue-50 duration-100 rounded-md cursor-pointer;

        &:hover > .icon {
            @apply opacity-100 text-slate-400;
        }

        .icon {
            @apply opacity-0 text-slate-400 text-sm duration-100 hover:font-bold;

            &.favorited {
                @apply opacity-100 font-bold text-slate-700;
            }
        }

        .name {
            @apply text-gray-900 text-base;
        }
    }
}
</style>
