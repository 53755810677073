<template>
    <Loader v-if="!isLoaded" />
    <div v-else id="install-login-form">
        <div v-if="error" class="error-message" :class="errorType" :innerText="error"></div>

        <h4>{{ tenantName }}</h4>

        <div v-if="fingerprintPng" class="fingerprint-submitting">
            <img :src="fingerprintPng" />
            <Loader size="2xl" />
        </div>

        <template v-else>
            <div v-if="hasFingerprintReader" class="fingerprint-wrap">
                <i class="fa fa-fingerprint" />
                <span>Sign in with fingerprint</span>
                <span class="or"><span>or</span></span>
            </div>

            <form ref="form" @submit.prevent="login">
                <div class="input-wrapper">
                    <i class="fa fa-user" />
                    <input v-model="user" v-autofocus type="text" placeholder="username" autocomplete="off" data-1p-ignore required />
                </div>
                <div class="input-wrapper">
                    <i class="fa fa-key" />
                    <input v-model="password" type="password" placeholder="PIN / password" autocomplete="off" data-1p-ignore required />
                </div>
                <button v-disabled="!canSubmit" class="primary">Sign In</button>
            </form>
        </template>
    </div>
</template>

<script lang="ts" setup>
import { formatError, handleError, maskForm, unmaskForm } from '@signal24/vue-foundation';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

import { GlobalState } from '@/global';
import { DesktopBridge, type IInboundEventPayloads } from '@/modules/desktop/helpers/bridge';
import { apiAuthClient } from '@/openapi-clients/auth';
import type { ILoginResponse } from '@/openapi-clients-generated/auth';
import Loader from '@/shared/components/loader.vue';
import { Auth } from '@/shared/services/auth.service';

const router = useRouter();
if (!GlobalState.isInstall) {
    router.replace('/zd/detect');
}

const form = ref<HTMLFormElement>();
const error = ref<null | string>(null);
const errorType = ref<string | null>();

const tenantName = computed(() => GlobalState.tenant.value?.name);

const isLoaded = ref(false);
const hasFingerprintReader = computed(() => GlobalState.installCapabilities.value?.fingerprintReader);
const fingerprintPng = ref<string>();
const user = ref('');
const password = ref('');
const canSubmit = computed(() => user.value.trim().length && password.value.trim().length);

async function login() {
    error.value = null;

    maskForm(form.value!);
    try {
        const handshake = await DesktopBridge.bridgeFunctions!.generateHandshake();
        const result = await apiAuthClient.installs.postInstallsAuthCredentials({
            requestBody: {
                handshake,
                username: user.value,
                password: password.value
            }
        });
        await handleLoginResponse(result);
    } catch (err) {
        unmaskForm(form.value!);
        reportError(err);
        error.value = formatError(err);
    }
}

async function handleFingerprint(data: IInboundEventPayloads['fingerprint']) {
    error.value = null;
    fingerprintPng.value = `data:image/png;base64,${data.Png}`;

    try {
        const handshake = await DesktopBridge.bridgeFunctions!.generateHandshake();
        const result = await apiAuthClient.installs.postInstallsAuthFp({
            requestBody: {
                handshake,
                fmd: data.Data
            }
        });
        await handleLoginResponse(result);
    } catch (err) {
        fingerprintPng.value = undefined;
        handleLoginError(err);
    }
}

async function handleLoginResponse(result: ILoginResponse) {
    await Auth.processLoginResponse(result);

    const intendedUrl = String(router.currentRoute.value.query.intendedUrl ?? '/');
    router.replace(intendedUrl ?? '/');
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function handleLoginError(err: any, isCritical?: boolean) {
    if (err instanceof Error || isCritical) {
        handleError(err);
        error.value = formatError(err);
    } else {
        error.value = err;
    }

    errorType.value = null;
}

const sessionLogoutCause = Auth.logoutCauseOnce;
if (sessionLogoutCause) {
    error.value = sessionLogoutCause;
    errorType.value = 'warning';
}

onMounted(async () => {
    DesktopBridge.addEventListener('fingerprint', handleFingerprint);
    isLoaded.value = true;
});

onBeforeUnmount(async () => {
    DesktopBridge.removeEventListener('fingerprint', handleFingerprint);
});
</script>

<style lang="scss" scoped>
#install-login-form {
    @apply max-w-sm flex flex-col;
}

h4 {
    @apply text-center text-slate-500;
}

.fingerprint-submitting {
    @apply mt-4 relative;

    img {
        @apply w-64 opacity-25;
    }

    .loader {
        @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
    }
}

.fingerprint-wrap {
    @apply mt-4 flex flex-col items-center gap-2 text-slate-400;

    i {
        @apply text-4xl;
    }

    .or {
        @apply mt-6 w-11/12 border-t flex justify-center;

        span {
            @apply bg-white p-1 -mt-3.5;
        }
    }
}

form {
    @apply mt-2 mx-auto w-72;

    .input-wrapper {
        @apply mt-2;

        input {
            @apply w-full;
        }
    }

    button {
        @apply mt-4 w-full;
    }
}
</style>
