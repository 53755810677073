import type { App } from 'vue';

function handleClickAction(event: MouseEvent) {
    setTimeout(() => {
        const target = event.target as HTMLElement;
        const allInputs = Array.from(document.querySelectorAll('input, textarea')) as (HTMLInputElement | HTMLTextAreaElement)[];
        const currentIndex = allInputs.findIndex(input => input === target);
        const afterInputs = allInputs.slice(currentIndex + 1);
        const firstTextInput = afterInputs.find(input => input.tagName === 'TEXTAREA' || input.type === 'text' || input.type === 'password');
        firstTextInput?.focus();
    }, 10);
}

export function registerNextFocusDirective(app: App) {
    app.directive('nextfocus', {
        mounted(el) {
            el.addEventListener('click', handleClickAction);
        },
        unmounted(el) {
            el.removeEventListener('click', handleClickAction);
        }
    });
}
