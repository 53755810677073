<template>
    <VfModal id="m-auth-enrollFingerprint" ref="modal">
        <template #header> Enroll Fingerprint </template>

        <p>Please press your finger down on the reader for 1 second then release.<br />Repeat this process until you have collected 5 samples.</p>

        <div class="fingerprint-samples">
            <div v-for="(sample, i) in samples" :key="i">
                <i v-if="!sample" class="fa fa-fingerprint"></i>
                <img v-else :src="`data:image/png;base64,${sample.Png}`" />
            </div>
        </div>

        <Loader v-if="isSubmitting" size="2xl" />

        <template #footer>
            <button type="button" @click="callback()">Cancel</button>
        </template>
    </VfModal>
</template>

<script lang="ts" setup>
import { showAlert, VfModal } from '@signal24/vue-foundation';
import { onBeforeUnmount, onMounted, ref } from 'vue';

import { GlobalState } from '@/global';
import { DesktopBridge, type IInboundEventPayloads } from '@/modules/desktop/helpers/bridge';
import { apiAuthClient } from '@/openapi-clients/auth';
import Loader from '@/shared/components/loader.vue';
import { withWait } from '@/shared/helpers/request.helpers';

const props = defineProps<{
    accountId?: string;
    callback: () => void;
}>();

const modal = ref<InstanceType<typeof VfModal>>();

type IFingerprintSample = IInboundEventPayloads['fingerprint'] | null;
const samples = ref<IFingerprintSample[]>([null, null, null, null, null]);
const isSubmitting = ref(false);

function handleFingerprint(data: IInboundEventPayloads['fingerprint']) {
    const targetIdx = samples.value.findIndex(s => s === null);
    if (targetIdx === -1) return;
    samples.value[targetIdx] = data;
    if (targetIdx === samples.value.length - 1) {
        performEnroll();
    }
}

async function performEnroll() {
    isSubmitting.value = true;

    await withWait(
        () => modal.value!.mask(),
        async () => {
            await apiAuthClient.tenantAccount.postTenantAccountEnrollFingerprint({
                requestBody: {
                    accountId: props.accountId ?? GlobalState.user.value?.id,
                    fmds: samples.value.map(s => s!.Data)
                }
            });
            await showAlert('Enroll Fingerprint', `${props.accountId ? 'The' : 'Your'} fingerprint has been enrolled successfully.`);
        }
    );

    props.callback();
}

onMounted(async () => {
    DesktopBridge.addEventListener('fingerprint', handleFingerprint);
});

onBeforeUnmount(async () => {
    DesktopBridge.removeEventListener('fingerprint', handleFingerprint);
});
</script>

<style lang="scss" scoped>
p {
    @apply text-center;
}

.fingerprint-samples {
    @apply mt-4 flex gap-2 justify-center;

    div {
        @apply w-32 h-32 bg-gray-100 rounded-md flex items-center justify-center;

        i {
            @apply text-6xl text-gray-200;
        }
    }
}

.loader {
    @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
}
</style>
