<template>
    <ul id="user-menu">
        <!-- <li @click="openSettings">Settings</li> -->
        <li v-if="hasAdditionalTenants" @click="openTenantSwitcher">Switch Tenant...</li>
        <li v-if="canChangePassword" @click="changePassword">Change Password</li>
        <li v-if="canEnrollFingerprint" @click="enrollFingerprint">Enroll Fingerprint</li>
        <li @click="logout">Logout</li>
    </ul>
</template>

<script lang="ts" setup>
import { presentOverlay } from '@signal24/vue-foundation';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { GlobalState } from '@/global';
import MEnrollFingerprint from '@/modules/auth/exports/m-enroll-fingerprint.vue';
import MSetPassword from '@/modules/auth/exports/m-set-password.vue';
import TenantSwitcherModal from '@/modules/auth/exports/m-tenant-switcher.vue';

defineExpose({ openTenantSwitcher });

const router = useRouter();
const hasAdditionalTenants = computed(() => (GlobalState.user.value?.tenants.length ?? 0) > 1);
const canChangePassword = computed(() => GlobalState.isInstall || GlobalState.user.value?.authMech?.includes('password'));
const canEnrollFingerprint = computed(() => GlobalState.isInstall && GlobalState.installCapabilities.value?.fingerprintReader);

// function openSettings() {
//     popover.value?.close();
// }

function openTenantSwitcher() {
    presentOverlay(TenantSwitcherModal, {});
}

function changePassword() {
    presentOverlay(MSetPassword, {});
}

function enrollFingerprint() {
    presentOverlay(MEnrollFingerprint, {});
}

function logout() {
    router.replace({ name: 'logout' });
}
</script>

<style lang="scss" scoped>
#user-menu {
    @apply py-1;

    li {
        @apply hover:bg-blue-600 hover:text-white p-3 cursor-pointer;
    }
}
</style>
