import type { RouteRecordRaw } from 'vue-router';

import { wrapRuntimeImport } from '@/shared/helpers/async.helpers';
import { makePermissionMeta } from '@/shared/helpers/permissions.helpers';

export const CRMRoutes: RouteRecordRaw[] = [
    {
        path: '',
        component: wrapRuntimeImport(() => import('./screens/list.vue')),
        meta: makePermissionMeta('crm')
    },
    {
        path: ':id',
        component: wrapRuntimeImport(() => import('./screens/contact.vue')),
        meta: makePermissionMeta('crm')
    },
    {
        path: 'memberships',
        component: wrapRuntimeImport(() => import('./screens/memberships.vue')),
        meta: makePermissionMeta('crm', 'membershipsRead')
    }
];
