import type { RouteRecordRaw } from 'vue-router';

import { wrapRuntimeImport } from '@/shared/helpers/async.helpers';

export const ItRoutes: RouteRecordRaw[] = [
    {
        path: 'freshdesk',
        component: wrapRuntimeImport(() => import('./screens/freshdesk.vue')),
        meta: { public: true }
    }
];
