import type { RouteRecordRaw } from 'vue-router';

import { wrapRuntimeImport } from '@/shared/helpers/async.helpers';
import { makePermissionMeta } from '@/shared/helpers/permissions.helpers';

export const FormsRoutes: RouteRecordRaw[] = [
    {
        path: '',
        component: wrapRuntimeImport(() => import('./screens/submissions.vue')),
        meta: makePermissionMeta('forms')
    }
];
