import { handleErrorAndAlert, showWait } from '@signal24/vue-foundation';

export enum RequestHelperFlags {
    Rethrow = 1,
    DontHandleError = 2,
    DontUnwaitOnSuccess = 4
}

export async function withErrorHandler<T>(fn: () => Promise<T>, flags?: RequestHelperFlags) {
    try {
        return await fn();
    } catch (e) {
        if (!(flags && flags & RequestHelperFlags.DontHandleError)) {
            handleErrorAndAlert(e);
            if (!(flags && flags & RequestHelperFlags.Rethrow)) {
                return;
            }
        }
        throw e;
    }
}

export async function withWait<T>(titleOrWait: string | (() => () => void), fn: () => Promise<T>, flags?: RequestHelperFlags) {
    const unwait = typeof titleOrWait === 'string' ? showWait(titleOrWait) : titleOrWait();
    let hadSuccess = false;
    try {
        const response = await withErrorHandler(fn, flags);
        hadSuccess = true;
        return response;
    } finally {
        if (!hadSuccess || !flags || !(flags & RequestHelperFlags.DontUnwaitOnSuccess)) {
            unwait();
        }
    }
}

// export async function withModalWait<T>(fn: () => Promise<T>, flags?: RequestHelperFlags) {
//     return withWait(
//         () => {
//             const modalEls = [...document.querySelectorAll('.vf-modal')];
//             const lastModalEl = modalEls[modalEls.length - 1];
//             if (!lastModalEl) return () => {};

//             const modalFns = (lastModalEl as any).__vnode.ctx.exposed as InstanceType<typeof VfModal>;
//             return modalFns.mask();
//         },
//         fn,
//         flags
//     );
// }
