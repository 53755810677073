import type { RouteRecordRaw } from 'vue-router';

import { wrapRuntimeImport } from '@/shared/helpers/async.helpers';

import Detect from './screens/detect.vue';

export const DesktopRoutes: RouteRecordRaw[] = [
    {
        name: 'desktop:detect',
        path: 'detect',
        component: Detect,
        meta: { public: true }
    },
    {
        path: 'register',
        component: wrapRuntimeImport(() => import('./screens/register.vue'))
    }
];
