import { isOpenApiError } from '@signal24/openapi-client-codegen/browser';
import { type ComponentInternalInstance, getCurrentInstance, onBeforeUnmount, onMounted, onUnmounted } from 'vue';
import type { Router } from 'vue-router';

import { GlobalState } from '@/global';
import { apiAuthClient } from '@/openapi-clients/auth';

import { useIdleTimeout } from '../helpers/idle.helpers';
import { Auth } from './auth.service';

const IdleTimeoutMs = import.meta.env.VITE_APP_AUTH_TIMEOUT_MINS * 60 * 1000;
const InstallIdleTimeoutMs = import.meta.env.VITE_APP_AUTH_TIMEOUT_MINS_INSTALL * 60 * 1000;
const IdleBlockingInstances = new Set<ComponentInternalInstance>();

export class SessionManagement {
    static install(router: Router) {
        this.useJwtRenewal(router);
        this.useSessionIdleTimeout(router);
    }

    static useJwtRenewal(router: Router) {
        let jwtRefreshInterval: number | null = null;

        const refreshJwt = async () => {
            try {
                const response = await apiAuthClient.login.postLoginRenew();
                Auth.processRenewalResponse(response);
            } catch (err) {
                // if we already get a 401, it's too late
                if (isOpenApiError(err) && err.status === 401) {
                    Auth.clearUserSessionAndLogin({ router });
                    return;
                }

                // no need to do anything else. checkJwtRefresh runs every 30 seconds
                // so it'll automatically try again.
            }
        };

        const checkJwtRefresh = () => {
            const jwtExp = Auth.authStorage.getItem('zs:auth:sessionJwtExp');
            if (!jwtExp || !/^\d+$/.test(jwtExp)) {
                return Auth.clearUserSession();
            }

            const renewTs = parseInt(jwtExp) - 300_000; /* 5 minutes */
            if (Date.now() > renewTs) {
                return refreshJwt();
            }
        };

        function scheduleJwtRefresh() {
            jwtRefreshInterval = window.setInterval(checkJwtRefresh, 30_000);
            checkJwtRefresh();
        }

        function clearScheduledJwtRefresh() {
            if (jwtRefreshInterval) clearInterval(jwtRefreshInterval);
        }

        onMounted(scheduleJwtRefresh);
        onUnmounted(clearScheduledJwtRefresh);
    }

    static useSessionIdleTimeout(router: Router) {
        useIdleTimeout({
            timeoutMs: 60_000,
            onIdle: (idleMs: number) => {
                const idleTimeout = GlobalState.isInstall ? InstallIdleTimeoutMs : IdleTimeoutMs;
                if (idleMs < idleTimeout) return;
                if (!GlobalState.user.value) return;
                if (IdleBlockingInstances.size > 0) return;
                Auth.clearUserSessionAndLogin({ router, cause: 'Your session timed out due to inactivity.' });
            }
        });
    }

    static useSessionTimeoutDisabler() {
        const instance = getCurrentInstance();
        if (!instance) return;

        onMounted(() => {
            IdleBlockingInstances.add(instance);
        });
        onBeforeUnmount(() => {
            IdleBlockingInstances.delete(instance);
        });
    }
}
